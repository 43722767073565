<template>
    <div class="view-root flex-column">
        <h3>日程组嘉宾列表</h3>
        <div style="text-align: right;">
            <el-button type="primary" size="small" @click="showGuestDialog = true">添加嘉宾</el-button>
        </div>
        <el-table :data="dataList" style="margin-top: 15px;" class="flex-1-overflow-y">
            <el-table-column type="index" prop="index" label="序号"></el-table-column>
            <el-table-column prop="name" label="嘉宾姓名"></el-table-column>
            <el-table-column prop="role" label="嘉宾角色"></el-table-column>
            <el-table-column prop="" label="头衔">
                <template slot-scope="scope">
                    <div style="display: flex;flex-wrap: wrap;gap:5px">
                        <el-tag v-for="(title, index) in scope.row.titles" :key="index" type="success">{{ title }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="company" label="所在公司"></el-table-column>
            <el-table-column prop="" label="头像">
                <template slot-scope="scope">
                    <img :src="scope.row.avatar_url" style="width: 72px;height: 72px;object-fit: cover;">
                </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" size="small" @click="removeGuestInfo(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="showGuestDialog" title="选择嘉宾" width="75%">
            <el-button @click="addGuest" type="primary">新增嘉宾</el-button>
            <div style="max-height: 500px;overflow-y: auto;margin-top:10px">
                <el-table :data="allGuests" :border="true">
                    <el-table-column type="index" prop="index" label="序号" width="100px"></el-table-column>
                    <el-table-column prop="name" label="嘉宾姓名"></el-table-column>
                    <el-table-column prop="role" label="嘉宾角色"></el-table-column>
                    <el-table-column prop="" label="头衔">
                        <template slot-scope="scope">
                            <div style="display: flex;flex-wrap: wrap;gap:5px">
                                <el-tag v-for="(title, index) in scope.row.titles" :key="index" type="success">{{ title
                                }}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="company" label="所在公司"></el-table-column>
                    <el-table-column prop="" label="头像">
                        <template slot-scope="scope">
                            <img :src="scope.row.avatar_url" style="width: 72px;height: 72px;object-fit: cover;">
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" size="small" @click="editGuest(scope.row)">编辑信息</el-button>
                            <el-button type="danger" size="small" @click="confirmGuest(scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showDialog" title="编辑嘉宾">
            <div>
                <el-form :model="guestInfo" :rules="rules" ref="guestInfo">
                <el-form-item label="嘉宾姓名" prop="name">
                    <el-input v-model="guestInfo.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="头像">
                    <el-upload
                    class="avatar-uploader"
                    action="/"
                    :show-file-list="false"
                    :before-upload="(file) => beforeAvatarUpload(file)"
                    >
                    <img
                        v-if="guestInfo.avatar_url"
                        :src="guestInfo.avatar_url"
                        style="object-fit: cover"
                        class="el-icon-plus avatar-uploader-icon"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="嘉宾角色" prop="role">
                    <el-select v-model="guestInfo.role" placeholder="请选择">
                    <el-option label="主持人" value="主持人"></el-option>
                    <el-option label="演讲人" value="演讲人"></el-option>
                    <el-option label="分享人" value="分享人"></el-option>
                    <el-option label="致辞人" value="致辞人"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                    <span>头衔</span>
                    <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    placeholder="请输入"
                    @tags-changed="(newTags) => (tags = newTags)"
                    />
                </el-form-item>
                <el-form-item label="所在公司" prop="">


                    <el-select v-model="guestInfo.company" placeholder="请选择" style="width: 400px;">
                        <el-option v-for="(companyName, index) in companyArr" :key="index"
                            :label="companyName.name"
                            :value="companyName.name"></el-option>
                    </el-select>
                    <!-- <el-input
                    v-model="guestInfo.company"
                    placeholder="请输入"
                    ></el-input> -->


                </el-form-item>
                <el-form-item label="">
                    <label>单位介绍</label>
                    <quill-editor
                    class="editor"
                    style="margin-bottom: 20px"
                    ref="myTextEditor"
                    v-model="guestInfo.company_intro"
                    :options="editorOption"
                    >
                    </quill-editor>
                </el-form-item>

                <el-form-item label="">
                    <label>简介</label>
                    <quill-editor
                    class="editor"
                    style="margin-bottom: 20px"
                    ref="myTextEditor"
                    v-model="guestInfo.intro"
                    :options="editorOption"
                    >
                    </quill-editor>
                </el-form-item>
                <el-form-item label="登录账号">
                    <el-input v-model="guestInfo.email" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input
                    v-model="guestInfo.password"
                    placeholder="请输入"
                    ></el-input>
                </el-form-item>
                </el-form>
                <div style="text-align: right">
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showDialog = false">取 消</el-button>
                    <el-button type="primary" @click="saveGuestInfo()">确 定</el-button>
                </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    getGuests,
    getSessionGroupDetail,
    updateSessionGroupGuests,
    getQiniuToken,
    updateGuest,
    getbrandPartys
} from '../../../api/api'
import '../../../assets/common/common.css'
import VueTagsInput from "@johmun/vue-tags-input";
export default ({
    components: {
        VueTagsInput,
    },
    name: 'index',
    data() {
        return {
            session_id: this.$route.query.session_group_id,
            dataList: [],
            allGuests: [],
            showGuestDialog: false,
            showDialog:false,
            guestInfo: {
                id: "",
                name: "",
                company: "",
                intro: "",
                avatar_url: "",
                role: "",
                company_intro: "",
                email: "",
                password: "",
            },
            tag: "",
            tags: [],
            companyArr:[]
        }
    },
    mounted() {
        this.fetchData()
        this.requestAllGuests()
    },
    methods: {

        confirmGuest(row) {
            let select_id = row._id.$id
            let ids = [];
            let enable = true
            for (let i = 0; i < this.dataList.length; i++) {
                let id = this.dataList[i]._id.$id
                if (id == select_id) {
                    enable = false
                    break
                } else {
                    ids.push(id)
                }
            }
            if (!enable) {
                this.$message.error('已经选择了该嘉宾')
                return
            }
            ids.push(select_id)
            updateSessionGroupGuests(this.session_id, ids).then((res) => {
                this.$message.success('添加成功')
                this.fetchData()
                this.showGuestDialog = false
            })
        },

        requestAllGuests() {
            getGuests(this.organizer_id).then((res) => {
                this.allGuests = res.data.data
            })
        },

        fetchData() {
            getSessionGroupDetail(this.session_id).then((res) => {
                this.dataList = res.data.data.guests
            })

            getbrandPartys().then((res) => {
                this.companyArr = res.data.data;
            });
        },
        removeGuestInfo(row) {
            this.$alert('你确定要删除此嘉宾吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        let ids = [];
                        for (let i = 0; i < this.dataList.length; i++) {
                            let id = this.dataList[i]._id.$id
                            if (id != row._id.$id) {
                                ids.push(id)
                            }
                        }
                        updateSessionGroupGuests(this.session_id, ids).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },
        async beforeAvatarUpload(file) {
            const result = await getQiniuToken();
            if (result.data.code === 0) {
                const token = result.data.data;
                this.observable = qiniu.upload(
                file,
                new Date().getTime() + file.name,
                token
                );
                const observer = {
                complete: (res) => {
                    this.guestInfo.avatar_url =
                    "http://mainbucket.reachable-edu.com/" + res.key;
                    console.log("url:" + this.guestInfo.avatar_url);
                },
                };
                this.observable.subscribe(observer);
            }
        },
        saveGuestInfo() {
            var titles = [];
            for (var i = 0; i < this.tags.length; i++) {
                titles.push(this.tags[i].text);
            }
            if (titles.length <= 0) {
                this.$message.error("请填写头衔");
                return;
            }
            this.guestInfo.titles = titles;
            updateGuest(this.guestInfo).then((res) => {
                this.$message.success("保存成功");
                this.requestAllGuests();
                this.showDialog = false;
            });
        },
        addGuest(){
            this.showDialog = true
            this.guestInfo = {
                id: "",
                name: "",
                company: "",
                intro: "",
                avatar_url: "",
                role: "",
                company_intro: "",
                email: "",
                password: "",
            }
            this.tag = ""
            this.tags = []
        },
        editGuest(row){
            this.showDialog = true
            this.guestInfo = row
            this.guestInfo.id = row._id.$id
            this.tag = ""
            this.tags = []
            for (var i = 0; i < row.titles.length; i++) {
                this.tags.push({ text: row.titles[i] });
            }
        }
    }
})
</script>
<style>
.ql-container {
    height: 160px !important;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
